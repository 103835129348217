
// This file contains all the LANGUAGES DROPDOWN styles
// This file is imported into the _shell.scss file

.fp-select-wrapper{
  @include space (padding, only-vertical, $spacer * 2 $spacer * 4);
  display: flex;

  .fp-select__styled {
    @include space (padding, horizontal, $spacer * 2);
    > svg {
      margin-left: auto;
    }
  }

  .fp-select{
    &__border-bottom{
      border-bottom: 1px solid black;
      border-radius: 0;
      min-width: 150px;
    }
  }
}

.fp-select-label {
  @include space(padding, right, $spacer * 4);
}

.fp-select {
  position: relative;
  cursor: pointer;
  select {
    display: none;
  }

  &__styled {
    @include flex(null, center, null);
    @include padding(0px $spacer);
    border-radius: $spacer;
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }
    &--display {

      @media (max-width: $tablet) {
        font-size: 16px!important;
        font-weight: $font-regular!important;
      }
    }
    &--options {
      @include padding($spacer 0);
      width: calc(100% + #{$spacer});
      position: absolute;
      left: 0;
      top: 100%;
      background: $white;
      border-radius: $spacer;
      z-index: 40;
      box-shadow: 0px 0px $spacer rgba($black, 0.2);
      display: none;
      li {
        @include padding($spacer);
        @include flex(space-between, center, null);
        transition: 0.2s;
        color: $black;
        &:hover {
          background: $accent-blue;
          color: $white;
          path {
            stroke: $white;
          }
        }
        p {

          @media (max-width: $tablet) {
            @include font(16px, $font-regular, null);
          }
        }
      }
    }
  }
}