// This file contains all the BASE styles
// This file is imported into the _delivery-variant-asc.scss file

html,
body {
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font(null, $text-bold, normal, $lh: 1.3);
  color: $text-color;
  @media(max-width: $tablet) {
    @include font(null, null, null, $lh: 2.1rem);
  }
}

p {
  @include font($text-m, $text-regular, normal, $lh: 1.5);
  color: $text-color;
}