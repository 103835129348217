// It is imported in the _layout.scss file

.fp-container {
  @include size($container-width, null);
  @include margins(0 auto);

  @media screen and (min-width: $mobile) and (max-width: $tablet){
    @include size($container-width-tablet, null);
  }

  @media (max-width: $mobile) {
    @include size(auto, null);
    @include margins(0 0.5rem);
  }

  &__small {
    @include size(83.3333333333%, null);
    @include margins(0 auto);
  }
}

.fp-page-content {
  flex: 1;
}

.fp-main-container {
  @include size($container-width, null);
  @include margins(0 auto);
  @include padding(0 0.5rem);
  background-color: $white;

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    @include size($container-width-tablet, null);
  }

  @media (max-width: $mobile) {
    @include size(100%, null);
  }
}
