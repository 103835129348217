// This file contains all the CANCEL SUBSCRIPTION FORM styles
// This file is imported into the _shell.scss file

.fp-cancel-subscription-form {
  &__form-wrapper {
    margin-top: 36px;
  }

  &__telefon {
    font-size: 24px;
    color: $color-primary;
  }

  &__input-group {
    margin-bottom: $spacer-v-xs;
  }

  &__input, &__textarea {
    @include font($font-16, $font-regular, normal);
    @include size(100%, $spacer * 12);
    @include padding(0 0 0 $spacer-v-xxxs);
    margin-top: $spacer-v-xxs;
    border-radius: $spacer;
    background: transparent;
    border: $border-light-gray;
    color: $text-color;

    &::placeholder {
      @include font($font-16, $font-regular, normal);
      color: $color-text--50;

      @media (max-width: $mobile) {
        @include font($font-16, $font-regular, normal);
      }
    }
  }

  &__fieldset {
    @include padding(0 0 $spacer-v-xs $spacer-v-xs);
    border-radius: $spacer;
    border: $border-light-gray;

    .radio-buttons {
      margin-top: $spacer-v-xxs;
      display: flex;
      flex-wrap: wrap;

      :first-child {
        margin-right: $spacer-v-xs;
      }

      @media (max-width: $mobile) {
        row-gap: $spacer-v-xxs;
      }
    }
  }

  &__date-input-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    svg path {
      fill: #323232
    }
  }

  &__textarea {
    height: unset;
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $spacer-v-m;

    button {
      @include padding(12px);
      @include flex(center, center);
      background: $color-primary 0 0% no-repeat padding-box;
      border-radius: 3px;
      border: 0;
      color: $white;
      cursor: pointer;
      font-size: 15px;

      &:hover {
        background-color: $text-color;
      }

      img {
        height: 24px;
        margin-right: 10px;
        width: 24px;

        path {
          fill: white
        }
      }
    }
  }
}