
.fp-form-wrapper {
  @include size(100%, null, $maxw: 500px);
  grid-column: 4/span 6;

  @include space(padding, top, $spacer * 8);

  > p {
    @include space(padding, only-vertical, $spacer * 4 $spacer * 6);
  }
}

.fp-form {
  @include flex(null, null, column);

  &_label {
    @include space(padding, bottom);
  }

  &_input {
    @include space(margin, bottom, $spacer * 4);
    @include space(padding, all, $spacer * 2 $spacer * 4);
    font-size: $font-16;

  }

  &_input-checkbox {
    @include space(margin, bottom);
  }

  &_group {
    &__row {
      @include space(padding, bottom, $spacer * 4);
      @include flex(space-between);
    }
  }

  &__message {
    @include margins($spacer * 2 0 0 0);
    color: $accent-magenta;
  }

  .fp-form_button {
    @include space(padding, all, $spacer * 4 $spacer * 6);
    @include font($font-14, $font-bold, normal);
    @include flex(center, center, null);
    line-height: $spacer * 4;
    display: inline-flex;
    background: $black;
    border-radius: $spacer;
    color: $white;
    position: relative;
  }
}
