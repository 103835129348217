// This file contains all the TOPICS styles
// This file is imported into the _shell.scss file

.fp-topics__teasers-column { margin-bottom: 25px; }

.fp-topics {

  &__manual-topic-teaser {
    background-color: #f7f6f4;
    padding: 2rem 1.5rem;

    @media (max-width: $mobile) {
      padding: 2rem 0.6rem;
    }

    &__super-title {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }

    &__title {
      font-size: 1.9rem;
      line-height: 1.3;
      margin-bottom: 20px;
    }

    &__content {
      display: grid;
      grid-template-columns: 290px auto;
      gap: 20px;

      &__text {
        a {
          color: $color-primary;
        }
        ul {
          list-style: inherit;
          padding-left: 20px;

          li {
            font-size: 1.25rem;
            line-height: 1.875rem
          }
        }
      }

      @media (max-width: $tablet) {
        display: block;
      }

      &__image {

        @media (max-width: $tablet) {
          margin-bottom: 1.688rem;
        }

        @media (max-width: $mobile) {
          margin-bottom: 1.5rem;
        }

        &__caption {
          color: #A39E99;
          font-size: 13px;
        }

        img {
          max-width: 100%;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__teasers-wrap {
    position: relative;

    .fp-article-teaser__image {
      @media (max-width: $tablet) {
        position: absolute;
        left: 0;
      }

      @media (max-width: $mobile) {
        position: relative;
      }
    }

    .fp-article-teaser {
      @media (max-width: $tablet) {
        padding-left: 262px;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0.5rem;
      }

      @media (max-width: $mobile) {
        padding-left: 0;
      }
    }
  }

  &__title {
    @include font($text-xxl, $text-bold, normal);
    margin-bottom: $spacer-v-m;

    @media (max-width: $tablet) {
      @include font($text-xl, null, null);
    }

    @media (max-width: $mobile) {
      @include font($text-l, null, null);
    }

    &--all {
      margin-bottom: $spacer-v-xs;
    }
  }

  &__subtitle {
    @include font($text-l, null, null);
    margin-bottom: $spacer-v-xl;
  }

  &__letters-title {
    font-size: $font-20;
  }

  &__letters-title-wrapper {
    margin-bottom: $spacer-v-xxl;
  }

  &__letters-wrapper {
    margin-bottom: $spacer-v-xl;
  }

  &__letters-color {
    color: $text-color;
  }

  &__topic-teaser-link {

    .fp-article-teaser {

      footer {
        padding-bottom: .75rem;
      }

      &--second {
        padding-bottom: 25px;
        border-bottom: 6px solid #EFECE8;

        header {
          padding-top: .75rem;
          border-top: 0.0625rem dotted #C6C1BA;
        }

        footer {
          padding-bottom: 0;
        }
      }
    }
  }

  &__topic-link {
    color: $color-primary;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: $spacer-v-m;
    line-height: 1;

    &__arrow {
      margin-left: 10px;
      svg {
        path {
          fill: $color-primary;
        }
      }
    }

    &:hover {
      color: $text-color;

      svg {
        path {
          fill: $text-color;
        }
      }
    }

  }

  &__search {
    display: flex;
    justify-content: center;

    &__form {
      display: flex;
      max-width: 600px;
      width: 100%;
      margin-bottom: 50px;
      position: relative;
    }

    &__input {
      width: 100%;
      border-radius: 3px 0 0 3px;
      border: 1px solid $fog-gray;
      padding: 10px 20px;
      color: $text-color;
      font-size: $font-16;
      font-weight: bold;
      border-right-color: transparent;

      &::placeholder {
        font-weight: normal;
      }

      &:focus {
        outline: none;
      }
    }

    &__button {
      width: 53px;
      height: 48px;
      background-color: $color-primary;
      border: none;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: $text-color;
      }
    }

    &__results {
      position: absolute;
      border: 1px solid $fog-gray;
      width: calc(100% - 49px);
      top: 50px;
      padding: 10px 20px;
      background-color: #fff;
      left: 0;
      box-shadow: 0 3px 6px rgba(50, 50, 50, 0.16);

      li {
        a {
          color: $text-color;
          font-size: $font-16;
          font-weight: bold;
          line-height: 1.8;

          mark {
            color: $color-primary;
            background-color: #fff;
          }
        }
      }
    }
  }

  &__filter-results {
    display: grid;
    grid-template-columns: auto auto auto;

    &__item {
      color: $text-color;
      font-weight: bold;
      line-height: 1.375;

      &:hover {
        color: $color-primary;
      }
    }

    @media (max-width: $tablet) {
      grid-template-columns: auto auto;
    }

    @media (max-width: $mobile) {
      grid-template-columns: auto;
    }
  }

  &__teasers {
    @include margins($spacer-v-xxxl 0 $spacer-v-xxxl 0);

    &__no-results {
      font-weight: 700;
      text-align: center;
      margin-top: 3rem;
    }

    &__button--load-more {
      margin: 3rem auto 0;
      max-width: 640px;
      transition: 0.2s ease-in-out all;

      svg {
        path {
          fill: $color-primary !important;
        }
      }

      .fp-load-more-btn__text {
        transform: translateX(-1rem);
        transition: 0.2s ease-in-out all;
      }



      &:hover {
        .fp-load-more-btn__text {
          color: $color-primary;
        }
      }
    }
  }

  &__teaser-section-title {
    @include font($text-l, $text-bold, normal);
    @include flex($dir: row);
    margin-bottom: $spacer-v-xl;

    &::before,
    &::after {
      content: '';
      flex: 1 1;
      border-bottom: 1px solid;
      margin: auto;
      color: $fog-gray;
    }

    &::before {
      margin-right: $spacer-h-xxxs;
    }

    &::after {
      margin-left: $spacer-h-xxxs;
    }
  }

  &__teasers-three-row-grid {
    @include grid(1fr 1fr 1fr, $spacer-h-xs, auto);

    @media (max-width: $tablet) {
      @include grid(1fr, 0, auto);
    }
  }

  hr {
    background-color: $fog-gray;
    border: none;
    height: 1px;
    margin-top: $spacer-v-xxxl;
    @media (max-width: $tablet) {
      margin-top: $spacer-v-xs;
    }
  }
}

