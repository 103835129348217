
// This file includes all the BASE styles for the project - iva:done
// It is imported in the _basics.scss file

* {
  box-sizing: border-box;
}

html,
body {
  @include font($text-m, $text-regular, normal, $family: $font-primary);
  @include size(null, null, $minh: 100vh);
  background: $white;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

body {
  @include flex(null, null, column);

  // stylelint-disable selector-no-qualifying-type
  &.overflow-hidden {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

em {
  @include font(null, null, italic);
}

strong {
  @include font(null, bold, null);
}

.hide-embed {
  display: none;
}
