// This file contains all the NEWSLETTER FORM styles
// This file is imported into the _delivery-variant-asc.scss file

.fp-newsletter {
  &__submit {
    background: $color-primary;
    &:hover {
      background: $color-primary-dark;
    }
  }

  &__checkbox-wrapper {
    input[type=checkbox]:checked ~ .fp-newsletter__checkbox {
      background: $color-primary;
      border: 1px solid $color-primary-dark;
    }
    p {
      a {
        color: $color-primary-dark;
      }
    }
  }
}

