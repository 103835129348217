
// This file contains all the HEADER SEARCH styles
// This file is imported into the _shell.scss file


.fp-main-header-search {
  @include size(100%, $spacer * 15 + 4);
  @include translate(0, -110%);
  @include transition(0.3s, $expo);
  background: $white;
  border-bottom: $border-light-gray;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;

  @media (max-width: $mobile) {
    @include size(null, $spacer * 11, null);
  }

  &.open {
    @include translate(0, 0);
    //z-index: 180;
    z-index: 100000000002; // forced by external ads script; more detail in PR - https://github.com/forward-distribution/asc-delivery-web/pull/433
  }

  &__container {
    @include flex(space-between, center, null);
    @include size(null, $spacer * 15 + 3, null);
    background: $white;
    position: relative;
    z-index: 49;

    @media (max-width: $mobile) {
      @include size(null, $spacer * 11 - 1, null);
    }
  }

  &__input-wrapper {
    @include flex(flex-start, center, null);
  }

  &__search {
    user-select: none;

    path {
      stroke: $dark-gray;
    }
  }

  &__input-wrapper {
    flex: 1;

    input {
      @include font($text-l, $text-bold, normal, $lh: $spacer * 10);
      @include margins(0 0 0 $spacer * 2);
      @include size(100%, $spacer * 10);
      @include padding(0 $spacer-h-xxs);
      background: transparent;
      border: 0;
      color: $dark-gray;

      @media (max-width: $mobile) {
        @include font($text-m, $text-bold, normal, $lh: $spacer * 8);
        @include size(100%, $spacer * 8);
      }

      &::placeholder {
        @include font($text-l, $text-bold, normal);
        color: $gray;

        @media (max-width: $mobile) {
          @include font($text-m, $text-bold, normal);
        }
      }
    }
  }

  &__close {
    @include flex(center, center, null);
    @include margins(0 0 0 $spacer * 2);
    cursor: pointer;

    @media (max-width: $mobile) {
      @include margins(0 0 0 $spacer);
    }

    path {
      stroke: $dark-gray;
    }
  }

  &__dropdown {
    @include padding($spacer * 2 0);
    @include margins(1px 0 0 0);
    @include translate(0, -100%);
    background: $white;
    box-shadow: 0 2px 6px rgba($black, 0.15);
    opacity: 0;
    overflow-y: scroll;
    transition: opacity 0.1s ease, transform 0.2s $expo;

    &.open {
      @include translate(0, 0);
      opacity: 1;
    }

    @media (max-width: $tablet) {
      @include size(null, 100vh);
    }
  }

  &__no-publications {
    @include flex(flex-start, center, null);
    @include margins($spacer * 2 0);
    margin-left: -#{$spacer * 2};

    @media (max-width: 900px) {
      @include padding(0 0 0 $spacer - 2);
      margin-left: 0;
    }

    svg {
      path {
        stroke: $gray;
      }
    }

    p {
      @include margins(0 0 0 $spacer * 2);
      color: $gray;
    }
  }

  &__error {
    @include margins($spacer * 2 0);

    p {
      color: red;
    }
  }

  @media print {    
    display: none !important;
  }
}

// Styles for the overlay

.fp-main-header-search__overlay {
  @include size(100vw, 100vh);
  @include transition(0.4s, ease);
  background: rgba($black, 0.5);
  bottom: 0;
  left: 0;

  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    //z-index: 47
    z-index: 100000000001 // forced by external ads script; more details in PR - https://github.com/forward-distribution/asc-delivery-web/pull/433
  }
}

// Styles for the search spinner

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.fp-main-header-search__spinner {
  @include size($spacer * 4, $spacer * 4);
  position: relative;

  &::before,
  &::after {
    @include size(inherit, inherit);
    animation: spin 0.6s infinite forwards;
    border: 2px solid transparent;
    border-radius: 100%;
    border-top: 2px solid $color-primary;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  &::after {
    animation: none;
    border: 2px solid $light-gray;
    z-index: 1;
  }

  &.login-spinner {
    &::before,
    &::after {
      border: 4px solid transparent;
      border-top: 4px solid $color-primary;
    }

    &::after {
      border: 4px solid $white;
    }
  }
}



// Styles for the search results

.fp-search-results {
  @include padding(0 0 0 $spacer * 2);
  max-height: calc(4 * 112px);

  &__teaser {
    @include padding($spacer * 2 0);
    border-bottom: $border-light-gray;
    display: block;
    outline: none;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__item {
    @include flex(flex-start, stretch, null);
  }

  &__image {
    @include size($spacer * 40, $spacer * 40);
    min-height: $spacer * 20;
    overflow: hidden;

    &:hover {
      .fp-search-results__title {
        text-decoration: underline;
      }

      .fp-search-results__image {
        img {
          @include scale(1.03);
        }
      }
    }

    img {
      @include responsive-image();
      @include transition(0.1s, ease);
    }

    @media (max-width: $mobile) {
      @include size($spacer * 30, $spacer * 30);
    }
  }

  &__details {
    @include size(null, 100%);
    @include flex(null, null, column);
    @include margins(0 0 0 $spacer * 4);
    flex: 1;
    position: relative;

    @media (max-width: $mobile) {
      @include margins(0 0 0 $spacer * 2);
    }
  }

  &__title,
  &__description,
  &__date {
    color: $text-color;
  }

  &__catchline {
    @include font($text-s, $text-bold, normal, $lh: $lh-s);
    @include margins(0 0 $spacer-v-xxxs / 2 0);
    color: $color-primary;
  }

  &__title {
    @include margins(0 0 $spacer 0);
    @include font($text-l, $text-bold, normal, $lh: $lh-l);

    @media (max-width: $mobile) {
      @include elipsis(3);
      font-size: $text-m;
      line-height: $lh-m;
    }
  }

  &__description {
    @include font($text-m, $text-regular, normal, $lh: $lh-m);
    @media(max-width: $mobile) {
      display: none;
    }
  }

  &__date {
    @include font($text-m, $text-regular, normal, $lh: $lh-m);
    @include margins($spacer * 4 0 0 0);
    color: $dark-gray;
    @media(max-width: $mobile) {
      font-size: $text-s;
      line-height: $lh-s;
    }
  }

  @media(max-width: $tablet) {
    @include size(null, auto, $maxh: none);
    @include padding(0);
    margin-bottom: $spacer * 15 + 20px; // Equal to the height of the search bar
  }

  @media (max-width: $mobile) {
    margin-bottom: $spacer * 11 + 20px; // Equal to the height of the search bar
  }
}
