
// This file includes all the MIXINS for the project iva:done
// This file is imported in the _basics.scss file

// Mixin for size. Includes width, height, and a max-width and max-height fallback
@mixin size($w, $h, $maxw: null, $maxh: null, $minw: null, $minh: null) {
  height: $h;
  max-height: $maxh;
  max-width: $maxw;
  min-height: $minh;
  min-width: $minw;
  width: $w;
}

// Mixin for transitions. $ms stands for milliseconds $ease is the easing
@mixin transition($ms, $ease) {
  transition: $ms $ease;
}

// Mixin for transform: translate
@mixin translate($x, $y) {
  transform: translate($x, $y);
}

// Mixin for transform: translate
@mixin scale($s) {
  transform: scale($s);
}

// Mixin for centrally positioning element that is affected by a position: absolute.
@mixin absolute-center($r: null, $l: null) {
  @include translate(-50%, -50%);
  left: $l;
  position: absolute;
  right: $r;
  top: 50%;
}

// Mixin for flexbox. Includes vertical and horizontal positioning, direction and a wrap fallback
@mixin flex($x: null, $y: null, $dir: null, $wrap: null) {
  align-items: $y;
  display: flex;
  flex-direction: $dir;
  flex-wrap: $wrap;
  justify-content: $x;
}
//Clear Element Float
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

// Mixin for grid. Includes grid columns, gap, rows
@mixin grid($c, $g, $r) {
  display: grid;
  grid-gap: $g;
  grid-template-columns: $c;
  grid-template-rows: $r;
}

// Mixin for handling vertical spacing using grid
@mixin v-spacer($amount) {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-row-gap: $amount;
}

// Mixin for margins.
@mixin margins($margins...) {
  margin: $margins;
}

// Mixin for padding.
@mixin padding($padding...) {
  padding: $padding;
}

// Font mixins
@mixin font($fsize, $fweight, $fstyle, $family: $font-primary, $lh: null) {
  font-family: $family;
  font-size: $fsize;
  font-style: $fstyle;
  font-weight: $fweight;
  line-height: $lh;
}

// Mixin for overflow elipsis. The value is the number of lines the text should overflow to
@mixin elipsis($l) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $l;
  overflow: hidden;
}

// Mixin for responsive images. Should be used in images that have figure/picture parent
@mixin responsive-image() {
  @include size(100%, 100%, $maxw: 100%);
  object-fit: cover;
  object-position: center;
}

// Spacer
@mixin space($type: margin, $direction: all, $amount: $spacer * 2) {
  @if $type == padding {
    @if $direction == all {
      padding: $amount;
    } @else if $direction == top {
      padding-top: $amount;
    } @else if $direction == right {
      padding-right: $amount;
    } @else if $direction == bottom {
      padding-bottom: $amount;
    } @else if $direction == left {
      padding-left: $amount;
    } @else if $direction == vertical {
      padding: $amount 0;
    } @else if $direction == only-vertical {
      $values: parse-values($amount);
      padding-bottom: map-get($values, 'value2');
      padding-top: map-get($values, 'value1');
    } @else {
      padding: 0 $amount;
    }
  } @else {
    @if $direction == all {
      margin: $amount;
    } @else if $direction == top {
      margin-top: $amount;
    } @else if $direction == right {
      margin-right: $amount;
    } @else if $direction == bottom {
      margin-bottom: $amount;
    } @else if $direction == left {
      margin-left: $amount;
    } @else if $direction == vertical {
      margin: $amount 0;
    } @else if $direction == only-vertical {
      $values: parse-values($amount);
      margin-bottom: map-get($values, 'value2');
      margin-top: map-get($values, 'value1');
    } @else {
      margin: 0 $amount;
    }
  }
}

// Mixing for position absolute and offset on the elements
@mixin pos-absolute($positions...) {
  @include position($positions...);
  position: absolute;
}

// Mixing for position fixed and offset on the elements
@mixin pos-fixed($positions...) {
  @include position($positions...);
  position: fixed;
}

// Mixing that handles the offset directions
@mixin position($positions...) {
  $positions: parse-directions($positions);
  bottom: map-get($positions, 'bottom');
  left: map-get($positions, 'left');
  right: map-get($positions, 'right');
  top: map-get($positions, 'top');
}

// Function that handles the offset directions
@function parse-directions($directions) {
  $top: 0;
  $bottom: 0;
  $left: 0;
  $right: 0;

  @if length($directions) > 0 {
    $d1: nth($directions, 1);
    $top: $d1;
    $bottom: $d1;
    $left: $d1;
    $right: $d1;
  }
  @if length($directions) > 1 {
    $d2: nth($directions, 2);
    $left: $d2;
    $right: $d2;
  }
  @if length($directions) > 2 {
    $d3: nth($directions, 3);
    $bottom: $d3;
  }
  @if length($directions) > 3 {
    $d4: nth($directions, 4);
    $left: $d4;
  }

  @return (top: $top, bottom: $bottom, left: $left, right: $right);
}

// Mixin for aspect ratio on images with responsive
@mixin ratio($width, $height) {
  &[data-ratio='#{$width}:#{$height}']:before {
    padding-top: $height / $width * 100%;
  }
  @media (max-width: 700px) {
    &[sm-data-ratio='#{$width}:#{$height}']:before {
      padding-top: $height / $width * 100% !important;
    }
  }
}

// Mixin for aspect ratio on Child images with responsive

@mixin ratio-on-child-image($width, $height) {
  &[data-ratio='#{$width}:#{$height}'] .fp-article__image:before {
    padding-top: $height / $width * 100%;
  }
  @media (max-width: 700px) {
    &[sm-data-ratio='#{$width}:#{$height}'] .fp-article__image:before {
      padding-top: $height / $width * 100% !important;
    }
  }
}
// Mixin for aspect ratio on Child images

@mixin ratio-on-child-image($width, $height) {
  //&[data-ratio='#{$width}:#{$height}'] .fp-article__image:before {
  //  padding-top: $height / $width * 100%;
  //}
  &[iva='#{$width}:#{$height}'] .fp-article__image:before {
    padding-top: $height / $width * 100%;
  }
}

// Mixin for element width and elements width versions
@mixin width-ver($value) {
  margin: 0 auto;
  max-width: $value;

  &_full-width {
    max-width: 100%;
  }
}

// Function for parsing values
@function parse-values($values) {
  $value1: null;
  $value2: null;
  @if length($values) > 0 {
    $v1: nth($values, 1);
    $value1: $v1;
    $value2: $v1;
  }
  @if length($values) > 1 {
    $v2: nth($values, 2);
    $value2: $v2;
  }
  @return (value1: $value1, value2: $value2);
}
