
// This file contains all the TOP NAVIGATION styles
// This file is imported into the _components.scss file

.fp-top-nav {
  @include flex(flex-start, center, null);
  overflow: hidden;
  position: relative;
  flex: 1;
  border-bottom: 1px solid $menu-neutral-dark;

  &__title {
    font-size: 39px;
    margin-bottom: 0;

    @media (max-width: $mobile)  {
      font-size: 25px;
    }
  }

  &__list {
    @include flex(flex-start, center, null);
    @include transition(0.2s, ease);
    @media (max-width: $mobile)  {
      overflow-x: auto;
      scrollbar-width: none;
      padding-right: 14px;
    }
  }

  &__list-item {
    padding: 14px 0 14px 30px;
    @include flex(null, center, null);
    user-select: none;
    white-space: nowrap;
    font-weight: bold;

    a {
      color: $text-color;
      transition: all 0.2s ease-in-out;
      position: relative;
      font-size: 20px;

      &:after {
        content: '';
        display: block;
        height: 2px;
        background-color: transparent;
        margin-top: 3px;
        width: 100%;
        position: absolute;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        &:after {
          background-color: $text-color;
        }
      }

      @media (max-width: $mobile)  {
        font-size: 1rem;
      }
    }

    &--active {
      a {
        &:after {
          content: '';
          display: block;
          height: 2px;
          background-color: $text-color;
          margin-top: 3px;
          width: 100%;
          position: absolute;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    &--first {
      padding-right: 0;
      padding-left: 0;
      font-weight: 400;
      background-color: #fff;
      position: relative;
      z-index: 10;

      > a {
        padding-right: 30px;
        border-right: 1px solid $menu-neutral-dark;
        position: relative;

        &:after {
          width: calc(100% - 30px);
        }

        &:hover {
          text-decoration: none;
        }

        @media (max-width: $mobile)  {
          padding-right: 14px;

          &:after {
            width: calc(100% - 14px);
          }
        }
      }

      @media (max-width: $mobile)  {
        padding-left: 14px;
      }
    }

    @media (max-width: $mobile)  {
      padding: 14px 0 14px 14px;
    }
  }

  &__arrow-container {
    @include flex(null, center, null);
    @include size(null, 100%);
    box-shadow: -$spacer * 3 0 6px rgba($white, 0.95);
    display: none;
    position: absolute;
    right: 0;
    z-index: 10;
    width: 100%;
    justify-content: space-between;
    pointer-events: none;
  }

  &__right-arrow {
    margin-left: auto;

    &:after {
      content: '';
      display: block;
      height: 48px;
      width: 100px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 52%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      right: 0;
      pointer-events: none;
    }
  }

  &__left-arrow {
    &:after {
      content: '';
      display: block;
      height: 48px;
      width: 100px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 52%,rgba(255,255,255,0) 99%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      left: 0;
      pointer-events: none;
    }
  }

  &__left-arrow,
  &__right-arrow {
    @include size($spacer * 6, $spacer * 6);
    @include flex(center, center, null);
    @include transition(0.2s, $expo);
    background: $white;
    border-radius: $spacer;
    cursor: pointer;
    position: relative;
    pointer-events: all;

    &:hover {
      background: $lighter-gray;
    }

  }

  @media (max-width: $mobile) {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    width: calc(100% + 1rem);
  }
}
