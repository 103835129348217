
// This file contains all the HEADER styles
// This file is imported into the _components.scss file

.fp-main-header {
  @include margins(0 0 $spacer-v-m 0);

  .fp-header {
    @include flex(null, null, column);
    background-color: $white;
    position: relative;

    &__sticky { // be wary if changing this, an external ads scripts depends on it - https://github.com/forward-distribution/asc-delivery-web/pull/433
      @include size($container-width, null, $maxw: $container-max-width);
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      z-index: 46; // changed by external ads script to z-index: 1mil; more details in PR - https://github.com/forward-distribution/asc-delivery-web/pull/433
      background: $white;
      @include transition(0.4s, ease-in);

      .fp-main-header__offer-link {
        @include size(130px, null);
        a {
          display: none;
        }
      }

      .fp-main-header__logo {
        @include size(24%, null, $maxw: 40%);
        @media(max-width: $mobile) {
          @include size(80%, null, $maxw: 100%);
        }
      }

      .fp-main-header__top {
        border-bottom: 0;
      }

      &-show {
        @include transition(0.4s, ease-in);
        transform: translate(-50%, 0);
      }

      @media print {    
        display: none !important;
      }
    }
  }

  &__top {
    @include flex(space-between, center, null);
    border-bottom: $nav-border-bottom;
    position: relative;
    //z-index: 91;
    background: $white;
  }

  &__links {
    @include flex(null, center, null);
    ul {
      @include flex(null, center, null);
      @include margins(0 $links-item-padding 0 0);
      @media(max-width: $mobile) {
        display: none;
      }
    }

    li, &--login {
      @include flex(center, center, null);
      user-select: none;
      white-space: nowrap;
    }

    &--login {
      svg {
        path {
          fill: $text-color;
        }
      }
    }

    a {
      @include font($text-m, $text-regular, normal, $lh: 1.4rem);
      @include padding($links-item-padding);
      color: $color-primary;
      &:hover {
        text-decoration: underline;
        .az-logo {
          opacity: 0.8;
        }
      }

      &.az-epaper {
        @include flex(null, center, null);
      }
    }

    .az-logo {
      @include size($text-m, auto);
      @include margins(0 $spacer-h-xxxs 0 0);
    }
  }

  &__middle {
    @include flex(space-between, flex-end, null);
    @include padding($spacer-v-m 0 0 0);
    @include margins(0 0 $spacer-v-m 0);
    user-select: none;
    @media (max-width: $mobile) {
      @include margins(0);
      justify-content: center;
      padding-bottom: $spacer-v-xxs;
    }
  }

  &__current-date {
    @media (max-width: $mobile) {
      display: none;
    }
    span {
      @include font($links-item-padding, $text-regular, normal);
      color: $dark-gray;
    }
  }

  &__offer-link {
    @media (max-width: $mobile) {
      display: none;
    }
    a {
      display: inline-block;
      img {
        @include size(auto, 35px);
      }
    }
  }

  &__menu-search-wrapper {
    @include flex(null, center, null);
    @include margins(0 $spacer-h-xs 0 0);

    a {
      cursor: pointer;
      display: inline-block;

      svg {
        path {
          stroke: $text-color;
        }
      }
    }

    &--search {
      @include margins(0 0 0 $spacer-h-xs);
      @include padding($nav-item-padding 0 $nav-item-padding 0);
    }
  }

  &__logo {
    @include size(40%, null, $maxw: 90%);
    @media (max-width: $mobile) {
      @include size(90%, null);
      @include padding(0 0 3px 0);
      border-bottom: $logo-border-responsive;
    }
    a {
      @include size(100%, null);
      display: inline-block;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__weather-widget {
    @include flex(null, flex-end, column);
    &--top {
      @include flex(null, center, null);
      @include margins(0 0 $spacer 0);
      aside {
        @include flex(null, center, null);
        @include margins(0 0 0 $spacer * 2);
      }
      span {
        @include font($font-16, $font-bold, normal);
        color: $color-text--80;
      }
    }
    &--bottom {
      span {
        @include font($font-12, $font-regular, normal);
        text-align: right;
      }
    }
  }
}
