@import 'slick-carousel/slick/slick.scss';
@import 'slick-carousel/slick/slick-theme.scss';

// Slick Customizations
// see https://github.com/kenwheeler/slick/#sass-variables

.slick-slider {
  .slick-track {
    @include flex();
  }
  .slick-slide {
    flex:1 1 0;
  }
}

// Reset the margins from the default styles
.slick-dotted.slick-slider {
  margin-bottom: 0px;
}