// This file contains all the BUTTON styles
// This file is imported into the _components.scss file

.fp-button {
  @include size(max-content, auto);
  @include margins(0 $spacer-h-s $spacer-v-xl 0);
  @include transition(0.2s, ease);
  border: 0;
  color: $white;
  cursor: pointer;
  letter-spacing: 0.05rem;
  outline: none;
  text-align: center;
  text-transform: capitalize;

  &__primary {
    @include padding($spacer-v-xxs $spacer-h-xs);
    @include font($text-l, $text-bold, null);
    background: $color-primary;
    border-radius: 0.1875rem;
    color: $white;

    &:hover {
      background: $text-color;
    }
  }

  &__secondary {
    @include font($text-m, $text-regular, null);
    @include padding($spacer-v-xxxs / 2 $spacer-h-xxs);
    background: $white;
    border: 0.0625rem solid $dark-gray;
    color: $text-color;

    &:hover {
      background: $light-gray;
    }
  }

  &__home {
    @include flex(null, center, null);

    &:visited {
      color: $white;
    }

    svg {
      @include size(10px, 20px);
      @include margins(0 0 0 4px);
    }
  }
}
