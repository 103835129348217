// This file contains all the HEADER styles
// This file is imported into the _delivery-variant-wb.scss file

.fp-main-header {
  &__offer-link {
    @include size(130px, null);
    a {
      display: none;
    }
  }
}