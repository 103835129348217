.fp-authors-list {
    &__sub-header {
        margin-top: 16px;
        margin-bottom: 24px;
        font-weight: unset;
    }

    &__alphabet-buttons {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        margin-bottom: 24px;
        align-content: center;
        flex-wrap: wrap;
        
        @media (max-width: $tablet) {
            justify-content: flex-start;
        }
    }

    &__letter {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        padding: 6px;
        border: $border-fog-gray;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover, &.clicked { 
          background-color: $text-color; 
          color: $white;
        }
    }

    &__selected-letter-wrapper {
        height: 48px;
        background-color: #f7f6f4;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        
    }
    
    &__clear-filter-btn {
        display: none;
        font-weight: unset;
        cursor: pointer;
        border: $border-fog-gray;
        color: $text-color; 
        border-radius: 3px;
        padding: 0.3rem 1rem;
        font-size: $font-16;

        &:hover {
          background-color: $text-color; 
          color: $white;
        }

        @media (max-width: $tablet) {
            padding: 0 1rem;
        }
    }   

    &__displayed-cards {
        margin-bottom: 80px;
    }

    &__author-name {
        a {
            color: unset;
            
            &:visited {
            color: unset;
            }

            &:hover {
                color: $color-primary;
            }
        }
    }

    &__no-authors-found,
    &__no-authors-found-by-filter {
        margin-bottom: 16px;
        font-weight: unset;
        text-align: center;
    }

    &__no-authors-found-by-filter {
        display: none;
    }

    &__author-card {
        display: flex;
        gap: 24px;
        align-items: center;
        padding: 24px 0;
        border-bottom: 1px solid #d1cec8;

        img {
            aspect-ratio: 1/1;
            border-radius: 50%;
        }
    }
}