
// This file contains all the SIDEBAR MENU styles
// This file is imported into the _components.scss file

.fp-sidebar-menu {
  @include size(375px, 100vh, $minh: 100vh);
  @include flex(null, null ,column);

  @include transition(0.3s, $expo);
  @include translate(-110%, 0);
  background: $white;
  bottom: 0;
  box-shadow: 5px 0 10px rgba($black, 0.15);
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1000000000005;
  overflow-y: scroll;

  &__inner {
    @include flex(null, null, column);
    padding: 15px;
    flex-grow: 1;
    @media(max-width: $mobile) {
      @include padding($spacer * 4);
      padding-bottom: $spacer-v-xxxl;
    }
  }

  @media(max-width: $mobile) {
    @include size(100vw, 100%, $minh: 100%);
  }

  &.open {
    @include translate(0, 0);
  }

  &__header {
    @include flex(space-between, center, null);
    @include margins(0 0 $spacer * 6 0);

    &--logo {
      @include size(auto, 25px);
      @include padding(0 0 1px 0);
      border-bottom: $footer-logo-border;

      img {
        @include size(auto, 100%);
      }
    }
    &--close {
      cursor: pointer;
      svg {
        path {
          stroke: $dark-gray;
        }
      }
    }
  }

  &__search-wrapper {
    @include flex(null, center, null);
    position: relative;
    background-color: $menu-neutral-dark;
    padding: 20px 16px;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__search {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
      path {
        stroke: $text-color;
      }
    }
    &__input {
      @include font($font-16, $font-regular, normal);
      @include size(100%, $spacer * 12);
      padding: 0 45px 0 21px;
      border-radius: 30px;
      background: #fff;
      border: 1px solid $dark-gray;
      color: $text-color;

      &::placeholder {
        @include font($font-16, $font-regular, normal);
        color: $text-color;

        @media (max-width: $mobile) {
          @include font($font-16, $font-regular, normal);
        }
      }
    }

    &__close {
      @include flex(center, center, null);
      @include transition(0.1s, linear);
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: $spacer * 2;
      transform: translateY(-50%);

      opacity: 0;
      visibility: hidden;
      user-select: none;

      svg {
        path {
          stroke: $dark-gray;
        }
      }
      &.visible {
        opacity: 1;
        visibility: visible;
        user-select: auto;
      }
    }
  }

  &__main-nav {

    .fp-sidebar-menu__nav--header {
      p {
        color: $color-primary;
        &:hover {
          a {
            text-decoration: underline;
          }
        }
        a {
          color: $color-primary;
          &:visited {
            color: $color-primary;
          }
        }
      }
    }

    .fp-sidebar-menu__nav--list {
      @include margins(0 0 $spacer * 6 0);
      @include flex($wrap: wrap);
      &:last-of-type {
        @include margins(0);
      }
    }

    .fp-sidebar-menu__nav--item {
      @include margins(0 $spacer / 2 $spacer * 2 0);
      &:before {
        content: '•';
        @include margins(0 $spacer);
        color: $dark-gray;
      }
      &:first-of-type {
        &:before {
          position: absolute;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  &__nav {
    margin-top: 20px;
    @include padding(0 0 $spacer * 8 0);
    border-bottom: 1px solid $menu-neutral-dark;;

    &--header {
      @include margins(0 0 $spacer * 4 0);

      p {
        @include font($text-s, $text-bold, normal);
        color: $color-primary;
        text-transform: uppercase;
        &:hover {
          a {
            text-decoration: underline;
          }
        }
        a {
          color: $color-primary;
          &:visited {
            color: $color-primary;
          }
        }
      }
    }

    &--item {
      @include margins(0 0 $spacer * 4 0);

      &:last-child {
        @include margins(0);
      }

      a {
        color: $dark-gray;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__nav--themen {
    padding-bottom: 10px;
  }

  &__nav--themen-title {
    color: $color-text-grey;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
  }

  &__nav--list--themen {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &__item {
      margin-right: 6px;
      margin-bottom: 16px;
      a {
        background-color: $menu-neutral-light;
        color: $color-text-grey;
        padding: 8px 12px;
        display: block;
      }
    }
  }

  .fp-social-media {
    @include padding($spacer * 8 0 0);
    a {
      border-right: 0;
    }

    &__twitter {
      &:hover {
        path {
          fill: $twitter;
        }
      }
    }

    &__instagram {
      &:hover {
        path {
          stroke: $instagram;
        }
      }
    }

    &__facebook {
      &:hover {
        path {
          fill: $facebook;
        }
      }
    }

    &__youtube {
      &:hover {
        path {
          fill: $youtube;
        }
      }
    }
  }
}

.fp-sidebar-menu__overlay {
  @include size(100vw, 100vh);
  @include transition(0.2s, $expo);
  background: rgba($black, 0.5);
  bottom: 0;
  left: 0;

  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 1000000000000;
  }
  @media (max-width: $mobile) {
    display: none;
  }
}
