
// This file contains all the FOOTER styles
// This file is imported into the _components.scss file

.fp-footer {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  @include padding($spacer-v-xl 0.5rem);
  background: $light-gray;

  @media(max-width: $mobile) {
    padding-top: $spacer-v-xxs;
  }

  .fp-container {
    @include size(100%, null);
    @include margins(0);
    @include flex();
    @media(max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__body {
    flex: 1;
    @media(max-width: $mobile) {
      @include margins($spacer-v-xs 0 0 0);
      order: 2;
    }
  }

  &__header {
    @include flex(null, flex-end, null);
    @media(max-width: $mobile) {
      @include flex(null, flex-start, column);
    }
    figure {
      @include size(14rem, auto);
      @include padding(0 0 1px 0);
      border-bottom: $footer-logo-border;

      a {
        @include size(null, inherit);
        display: inline-block;
      }

      svg {
        @include size(auto, inherit);
      }
    }
  }

  &__copyright {
    @include font($links-item-padding, $text-regular, normal);
    @include padding(0 0 0 $spacer-v-xl);
    display: inline-block;
    color: $dark-gray;
    @media(max-width: $mobile) {
      @include padding($spacer-v-xs 0 0 0);
    }
  }

  &__logo {
    a {
      display: inline-block;
    }
    img {
      width: 100%;
      height: 30px;
    }
  }

  &__links {
    @include flex(flex-start, null, column, $wrap: wrap);
    @include margins(0 0 $spacer-v-m 0);
  }

  &__link {
    @include font($links-item-padding, $text-bold, normal, $lh: 1.6875rem);
    @include padding(0 $spacer-h-m 0 0);
    @include margins(0 $spacer-h-m $spacer-v-xs 0);
    border-right: $footer-link-border;
    color: $text-color;
    display: inline-block;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }

    &:hover {
      color: $color-primary;
      text-decoration: underline;
    }
  }

  .fp-social-media {
    @include margins(0);
  }

  &__back-to-top {
    @include size(40px, 40px);
    @include flex(center, center, null);
    @include transition(0.15s, ease);
    border-radius: 50%;
    background: $color-primary;
    color: $white;
    @media(max-width: $mobile) {
      position: static;
      order: 1;
      align-self: flex-end;
    }

    svg {
      @include size(22px, 22px);
      path {
        fill: $white;
      }
    }

    &:hover {
      background: $color-primary-dark;
    }
  }
}

// Responsive FOOTER styles

.fp-container__desktop {
  @media(max-width: 1150px) {
    display: none;
  }
}

.fp-container__responsive {
  display: none;
  @media(max-width: 1150px) {
    display: block;
  }

  .fp-footer__header {
    figure {
      @include size(100%, 25px);
    }
  }

  .fp-footer__links-container {
    @include flex(flex-start, stretch, column, $wrap: initial);
  }

  .fp-accordion {
    border-top: $border-light-gray;

    &__header {
      @include flex(flex-start, center, null);
      @include size(100%, $spacer * 11);
      @include margins(0);
    }

    &__content {
      @include padding(0 0 $spacer * 4 0);
      display: none;

      &.open {
        display: block;
      }
    }

    ul {
      @include grid(repeat(2, max-content), null, auto);
      grid-column-gap: $spacer * 6;
      grid-row-gap: $spacer * 2;

      li {
        @include margins(0);
      }
    }
  }
}

// Cookie consent
.cookie-consent {
  @include flex(center, center, null);
  @include padding(8px 0px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 500;

  background-color: $white;
  border-top: $border-light-gray;
  p {
    margin-right: 16px;
  }
  .fp-button {
    @include padding(8px 24px);
  }

  @media (max-width: $mobile) {
    @include flex(null, null, column);
    @include padding(8px);
    p {
      margin-bottom: 16px;
      margin-right: 0px;
    }
  }
}

.hide {
  display: none;
}
