// This file contains the styles for the SOCIAL MEDIA
// It is imported in the _components.scss file

.fp-social-media {
  @include flex(null, center, null);
  a {
    @include flex(center, center, null);
    @include padding(0 $spacer-h-m 0 0);
    @include margins(0 $spacer-h-m 0 0);
    border-right: $footer-link-border;
    &:last-child {
      @include margins(0);
      border-right: 0;
    }

    svg {
      @include size(22px, 22px);
    }
  }
  path {
    @include transition(0.15s, ease);
  }

  &__twitter {
    &:hover {
      path {
        fill: $twitter;
      }
    }
  }

  &__instagram {
    &:hover {
      path {
        stroke: $instagram;
      }
    }
  }

  &__facebook {
    &:hover {
      path {
        fill: $facebook;
      }
    }
  }

  &__youtube {
    &:hover {
      path {
        fill: $youtube;
      }
    }
  }
}