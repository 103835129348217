.m-ad-unit-1 {
  overflow: hidden;
  max-width: 1380px;
  width: 97%;
  margin: 0 auto;
}

.m-ad-unit-2 {
  margin: 2% auto;
  max-width: 800px;
  width: 100%;
}

div[data-ad-position='skyscraper'], div#westfalen-blatt\.de_sky_1 {
  position: absolute;
  left: 100%;
  top: 49px;
  padding-left: 10px;

  @media(max-width: $mobile) {
    display: none;
  }
}

div[data-ad-position='superbanner'], div#westfalen-blatt\.de_lb_1 {
  @include size($superbanner-w, null, $minh: $superbanner-h);
  @include margins($spacer-v-xxs auto 0 auto);
  @include flex(center, center, null);

  @media(max-width: $mobile) {
    @include size($superbanner-w-m, auto, $minh: $superbanner-h-m);
    @include margins($spacer-v-xxs auto 0 auto);
  }
}

div[data-ad-position='billboard1'], div#westfalen-blatt\.de_bb_1 {
  @media(min-width: $mobile) {
    @include size($billboard1-w, $billboard1-h);
  }
}

div[data-ad-position='outbrain'] {
  background-color: $white;
}

// div[data-ad-position='plista'] {
//   background-color: $white;
// }

.fp-ad-rectangle__7 {
  @media(min-width: $mobile) {
    display: none;
  }
}

.fp-powerstories-embed-ad-tag {
  display: block;
  font-size: 11px;
  color: #aaa;
  height: 18px;
  margin-left: 13px;
}
