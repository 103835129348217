.fp-dedicated-search {
  &__search-wrapper {
    @include flex(null, center, null);
    @include margins(0 0 1rem 0);
    position: relative;
  }

  &__search {
    @include margins(0 $spacer * 2 0 0);
    position: absolute;
    left: $spacer * 2;
    top: 50%;
    transform: translateY(-50%);

    svg {
      path {
        stroke: $text-color;
      }
    }
    &__input {
      @include font($font-16, $font-regular, normal);
      @include size(100%, $spacer * 12);
      @include padding(0 0 0 $spacer * 10);
      border-radius: $spacer;
      background: transparent;
      border: $border-light-gray;
      color: $text-color;

      &::placeholder {
        @include font($font-16, $font-regular, normal);
        color: $color-text--50;

        @media (max-width: $mobile) {
          @include font($font-16, $font-regular, normal);
        }
      }
    }

    &__close {
      @include flex(center, center, null);
      @include transition(0.1s, linear);
      cursor: pointer;
      position: absolute;
      top: 50%;
      right: $spacer * 2;
      transform: translateY(-50%);

      opacity: 0;
      visibility: hidden;
      user-select: none;

      svg {
        path {
          stroke: $dark-gray;
        }
      }
      &.visible {
        opacity: 1;
        visibility: visible;
        user-select: auto;
      }
    }
  }

  &__load-more {
    @include margins(2rem 0 0 0);
    display: none;

    &--has-more {
      @include flex();
    }
  }
}