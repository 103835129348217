// This file contains all the SEARCH RESULTS PAGE styles
// This file is imported into the _shell.scss file

.fp-search-results-page {
  @include padding(0 0 $spacer-v-xxxl 0);
  &__header {
    @include margins(0 0 $spacer-v-m 0);
    h2 {
      @include font($text-xl, $text-bold, normal, $lh: $lh-xl);
    }
  }

  &__list {
    .fp-search-results__teaser {
      @include padding($spacer-v-xs 0);
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .fp-search-results__title {
    &:hover {
      color: $color-primary;
    }
  }
}