
// This file contains the styles for the ERROR PAGE
// It is imported in the _layout.scss file

.fp-error {
  @include size($container-width, null);
  @include padding(0 0 $spacer-v-xxxl 0);
  @include margins(0 auto);
  flex: 1;

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    @include size($container-width-tablet, null);
  }

  @media (max-width: $mobile) {
    @include size(auto, null);
    @include margins(0 0.5rem);
  }

  &__header {
    @include margins(0 0 $spacer-v-m 0);
    display: grid;
    grid-template-columns: 55px 1fr;
    grid-gap: $spacer-h-xs;
    align-items: center;
    @media(max-width: $tablet) {
      align-items: start;
    }
  }

  &__icon {
    @include size(55px, 55px);
    @include flex(center, center, null);
    border-radius: 120px;
    background: $color-primary;
  }

  &__title {
    @include font($text-xxxl, $text-bold, normal, $lh: $lh-xxxl);
    color: $text-color;
    @media(max-width: $mobile) {
      font-size: $text-xxl;
      line-height: $lh-xxl;
    }
  }

  p {
    @include font($text-l, $text-regular, normal, $lh: $lh-l);
    color: $text-color;
  }

  &__signature {
    @include margins($spacer-v-m 0 0 0);
    span {
      text-transform: uppercase;
    }
  }

  .fp-button {
    @include margins($spacer-v-xxxl 0 0 0);
  }
}
