// This file contains all the BREADCRUMBS styles
// This file is imported into the _components.scss file


.fp-homepage {
  .fp-breadcrumbs {
    display: none;
  }
}

.fp-breadcrumbs {
  @include flex(null, center, row, wrap);
  @include margins(0 0 $spacer-v-l 0);
  overflow: hidden;

  &__item {
    @include margins(0 $spacer-h-xxxs 0 0);
    @include font($text-m, $text-regular, normal, $lh: $lh-m);
    h1 {
      @include font($text-m, $text-regular, normal, $lh: $lh-m);
    }
    h1, a {
      @include elipsis(1);
    }
    a {
      color: $color-primary;
      &:hover {
        text-decoration: underline;
      }
      &:visited {
        color: $color-primary;
      }
    }
  }

  span {
    @include margins(0 $spacer-h-xxxs 0 0);
    &:last-child {
      display: none;
    }
  }
}