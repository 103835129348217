.placeholder-youtube {
  background-image: url("../../../public/static/images/placeholder-youtube.svg");
  height: 200px;
  width: 476px;

  @media (max-width: $mobile) {
    background-image: url("../../../public/static/images/placeholder-youtube-mobile.svg");
    width: auto;
    padding-bottom: calc(100% * 200 / 351);
    height: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.placeholder-twitter {
  background-image: url("../../../public/static/images/placeholder-twitter.svg");
  height: 117px;
  width: 476px;

  @media (max-width: $mobile) {
    background-image: url("../../../public/static/images/placeholder-twitter-mobile.svg");
    padding-bottom: calc(100% * 111 / 351);
    height: 0;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.placeholder-facebook {
  background-image: url("../../../public/static/images/placeholder-facebook.svg");
  height: 398px;
  width: 476px;

  @media (max-width: $mobile) {
    background-image: url("../../../public/static/images/placeholder-facebook-mobile.svg");
    padding-bottom: calc(100% * 398 / 351);
    height: 0;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.placeholder-instagram {
  background-image: url("../../../public/static/images/placeholder-instagram.svg");
  height: 342px;
  width: 476px;

  @media (max-width: $mobile) {
    background-image: url("../../../public/static/images/placeholder-instagram-mobile.svg");
    height: 0;
    padding-bottom: calc(100% * 334 / 351);
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.placeholder-spotify, .placeholder-tiktok {
  background-image: url("../../../public/static/images/placeholder-default.svg");
  height: 117px;
  width: 476px;

  @media (max-width: $mobile) {
    background-image: url("../../../public/static/images/placeholder-default-mobile.svg");
    height: 0;
    padding-bottom: calc(100% * 111 / 351);
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.social-media-placeholder {
  display: flex;
  justify-content: center;

  .placeholder-wrapper {
    width: 500px;
    padding: 12px;
    background-color: $light-gray;
    border-radius: 9px;

    .placeholder-title {
      margin-top: 10px;
      font-family: $font-primary;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }

    .placeholder-description {
      font-family: $font-primary;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    .placeholder-trigger {
      background-color: $color-primary;
      text-align: center;
      padding: 4px;
      border-radius: 6px;
      color: #ffffff;
      margin: 12px 0;
      cursor: pointer;

      &:hover {
        background-color: $color-primary-dark;
      }

      &::after {
        content: 'INHALTE AKTIVIEREN';
        font-family: $font-primary;
      }
    }

    .placeholder-legal-text {
      font-family: $font-primary;
      font-weight: 300;
      font-size: 11px;
      line-height: 15px;
      color: #000000;

      a {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: $mobile) {
  .social-media-placeholder {
    .placeholder-wrapper {
      width: auto;
    }
  }
}

@media (max-width: $mobile-small) {
  .social-media-placeholder {
    width: 100vw;
    left: 50%;
    right: 50%;
    position: relative;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .m-iframe-new {
    .social-media-placeholder {
      width: 100%;
      left: 0;
      right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}