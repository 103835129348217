
// This file contains all the NAVIGATION styles
// This file is imported into the _components.scss file

.fp-main-nav {
  @include flex(center, center, null);
  @include size(100%, null);
  border-top: $nav-border-top;
  border-bottom: .0625rem solid #a39e99;
  position: relative;
  overflow: hidden;

  @media (max-width: $tablet) {
    display: none;
  }

  &__list {
    @include flex(flex-start, center, null);
    @include transition(0.2s, ease);
  }

  &__list-item {
    user-select: none;
    white-space: nowrap;
    position: relative;

    &:nth-child(2) {
      @include margins(0 0 0 $spacer * 2);
    }

    a {
      @include font($text-l, $text-bold, normal);
      @include padding($nav-item-padding);
      @include margins(auto $nav-item-margins);
      @include flex(null, center, null);
      color: $text-color;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      &:hover {
        color: $color-primary;

        svg {
          path {
            fill: $color-primary;
          }
        }
      }
    }
  }

  &__arrow-container {
    @include flex(null, center, null);
    @include size(null, 100%);
    background: $white;
    box-shadow: -$spacer * 3 0 6px rgba($white, 0.95);
    display: none;
    position: absolute;
    right: 0;
    z-index: 10;
  }

  &__left-arrow,
  &__right-arrow {
    @include size($spacer * 6, $spacer * 6);
    @include flex(center, center, null);
    @include transition(0.2s, $expo);
    background: $white;
    border-radius: $spacer;
    cursor: pointer;

    &:hover {
      background: $lighter-gray;
    }
  }

  &__arrow {
    @include margins(0 0 0 4px);
    svg {
      path {
        fill: $text-color;
      }
    }
  }
}