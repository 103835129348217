
// This file contains all the NEWSLETTER FORM styles
// This file is imported into the _shell.scss file

.fp-newsletter {
  h4 {
    @include font($font-16, $font-bold, normal);
    @include margins(0 0 $spacer * 4 0);
  }
  &__input-wrapper {
    @include flex(null, center, null);
    @media(max-width: $mobile) {
      flex-direction: column;
    }
  }
  aside {
    @include size(100%, null);
    position: relative;
  }
  &__input {
    @include size(100%, $spacer * 12);
    @include font($font-16, $font-regular, normal);
    @include padding(0 $spacer * 2);
    border-radius: $spacer 0 0 $spacer;
    background: transparent;
    border: $border-light-gray;
    color: $dark-gray;

    &::placeholder {
      @include font($font-16, $font-regular, normal);
      color: $light-gray;
      @media (max-width: $mobile) {
        @include font($font-16, $font-regular, normal);
      }
    }

    @media(max-width: $mobile) {
      border-radius: $spacer;
    }
  }

  &__close {
    @include transition(0.1s, linear);
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: $spacer * 2;
    transform: translateY(-50%);

    opacity: 0;
    visibility: hidden;
    user-select: none;
    &.visible {
      opacity: 1;
      visibility: visible;
      user-select: auto;
    }
  }

  &__submit {
    @include size(null, $spacer * 12);
    @include padding(0 $spacer * 4);
    @include font($font-16, $font-bold, normal);
    @include transition(0.15s, ease);
    white-space: nowrap;
    cursor: pointer;
    background: $color-primary;
    color: $white;
    border-radius: 0 $spacer $spacer 0;
    border: 0;
    &:hover {
      background: lighten($color-primary, 5%);
    }

    @media(max-width: $mobile) {
      @include size(100%, null);
      @include margins($spacer 0 0);
      border-radius: $spacer;
    }
  }

  &__checkbox-wrapper {
    @include margins($spacer * 4 0 0 0);
    @include flex(null, center, null);
    cursor: pointer;

    p {
      @include margins(0 0 0 $spacer * 2);
      @include font($font-14, $font-regular, normal);
      a {
        color: $color-primary-light-2;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    input[type=checkbox] {
      opacity: 0;
      position: absolute;
      &:checked ~ .fp-newsletter__checkbox {
        background: $color-primary-light-2;
        border: 1px solid $color-primary-light-2;
        svg {
          transform: scale(1);
        }
      }
    }
  }

  &__checkbox {
    @include size($spacer * 5, $spacer * 5);
    @include flex(center, center, null);
    border-radius: $spacer;
    border: $border-light-gray;
    cursor: pointer;
    position: relative;

    svg {
      @include transition(0.25s, $expo);
      transform: scale(0);
      transform-origin: center;
      cursor: pointer;
      path {
        stroke: $white;
      }
    }
  }
}