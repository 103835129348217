.fp-paywall-layer-flyin-wrapper { 
  position: fixed;
  width: 100%;
  display: none;
  z-index: 10000000013;
}

.fp-paywall-layer-flyin-wrapper.fp-paywall-layer-footer-light-flyin {
  transition: bottom 0.5s ease 0s;
}

.fp-paywall-layer-flyin-wrapper.fp-paywall-layer-footer-light-flyin.collapsed {
  bottom: -96px;
}

.fp-paywall-layer-flyin-wrapper.fp-paywall-layer-footer-flyin,
.fp-paywall-layer-flyin-wrapper.fp-paywall-layer-footer-light-flyin {
  bottom: 0;
}

.fp-paywall-layer-flyin-light--button:hover svg {
  cursor: pointer;
  stroke: #c6c1ba;
}

.fp-paywall-layer-bubble-wrapper {
  position: absolute;
  top: 45px;
  background: white;
  z-index: 100;
  right: 100px;

  &--second-item {
    right: 185px;
  }

  &--first-item {
    right: 300px;
  }
}

@media (max-width: 785px) { 
  .fp-paywall-layer-bubble-wrapper {
    display: none;
  }
}