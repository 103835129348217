// This file contains all the TYPOGRAPHY styles for the project
// It is imported in the _basics.scss file -delivery

.text-10--regular {
  @include font($font-10, $font-regular, normal);
}

.text-10--bold {
  @include font($font-10, $font-bold, normal);
}

.text-12--regular {
  @include font($font-12, $font-regular, normal);
}

.text-12--bold {
  @include font($font-12, $font-bold, normal);
}

.text-14--regular {
  @include font($font-14, $font-regular, normal, $lh: 1.5);
}

.text-14--bold {
  @include font($font-14, $font-bold, normal, $lh: 1.5);
}

.text-14--black {
  @include font($font-14, $font-black, normal, $lh: 1.5);
}

.text-16--regular {
  @include font($font-16, $font-regular, normal, $lh: 1.5);
}

.text-16--bold {
  @include font($font-16, $font-bold, normal, $lh: 1.5);
}

.text-18--regular {
  @include font($font-18, $font-regular, normal, $lh: 1.5);
}

.text-18--bold {
  @include font($font-18, $font-bold, normal, $lh: 1.5);
}

.text-18--black {
  @include font($font-18, $font-black, normal, $lh: 1.5);
}

.text-20--bold {
  @include font($font-20, $font-bold, normal);
}

.text-24--regular {
  @include font($font-24, $font-regular, normal);
}

.text-24--bold {
  @include font($font-24, $font-bold, normal);
}

.text-32--regular {
  @include font($font-32, $font-regular, normal);
}

.text-32--bold {
  @include font($font-32, $font-bold, normal);
}

.text-32--extra-bold {
  @include font($font-32, $font-extrabold, normal);
}

.text-32--black {
  @include font($font-32, $font-black, normal);
}

.text-40--bold {
  @include font($font-40, $font-bold, normal);
}

.text-40--black {
  @include font($font-40, $font-black, normal);
}
